/* Menu Shop-by principal */
.shop-by-menu {
  display: flex;
  justify-content: center; /* Centre le menu sur grand écran */
  margin-top: 20px;
}

/* Liste des éléments Shop-by */
.shop-by-items {
  display: flex;
  list-style: none;
  padding: 0;
  overflow-x: hidden; /* Masque la barre de défilement par défaut */
  gap: 10px;
  max-width: 100%;
}

/* Élément Shop-by individuel */
.shop-by-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #1a1818; /* Bordure inchangée */
  padding: 5px 8px; /* Réduit le padding pour affiner la hauteur */
  white-space: nowrap; /* Empêche le texte de se casser en plusieurs lignes */
  width: auto; /* Permet à la largeur de s'ajuster automatiquement au contenu */
}


/* Bouton flottant "Shop by" */
.toggle-shopby-btn {
  position: fixed;
  bottom: 300px;
  left: 70%;
 top: 500px;
  border-radius: 40px;
  cursor: pointer;
  border: 1px solid #298321;
  background-color: white;
  height: 40px;
  color: #298321;
  display: flex;
  align-items: center;
  z-index: 100;
  
}

/* Style responsive pour les petits écrans */
@media (max-width: 992px) {
  .shop-by-items {
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    padding: 0 10px;
  }

  .shop-by-menu {
    justify-content: flex-start;
    overflow-x: auto;
    margin-top: 10px;
  }

  .shop-by-item {
    padding: 8px 15px; /* Réduit également le padding vertical */
    border: 1px solid #ddd;
  }
  .toggle-shopby-btn {
    position: fixed;
    bottom: 300px;
    left: 70%;
   top: 500px;
    border-radius: 40px;
    cursor: pointer;
    border: 1px solid #298321;
    background-color: white;
    height: 40px;
    color: black;
    display: flex;
    align-items: center;
    z-index: 100;
    
  }
}
/* Label "Shop by" */
.shop-by-label {
  margin-right: 20px;
  color: black;
  font-weight: bold;
  font-size: 16px;
}

