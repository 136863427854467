/* Overlay sombre */
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur sombre avec opacité */
  z-index: 999; /* Assurez-vous qu'il est en dessous du modal */
}

/* Conteneur du modal */
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 24px; /* Bordure arrondie pour le modal */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  padding: 20px;
  z-index: 1000; /* Au-dessus de l'overlay */
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Contenu du modal */
.custom-modal-content {
  padding: 16px;
}

/* En-tête du modal */
.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-modal-title {
  font-size: 16px;
  font-weight: bold;
}

.custom-modal-close-button {
  color: #333;
}

/* Corps du modal */
.custom-modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-modal-form-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-modal-input .MuiOutlinedInput-root {
  background-color: white;
  border-radius: 24px !important; /* Bordure arrondie pour les champs de texte */
}

.custom-modal-form-options {
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-modal-checkbox {
  margin-right: 8px;
}

.custom-modal-label {
  font-size: 16px;
}

/* Bouton "Apply" avec bordure arrondie */
.custom-modal-apply-button {
  padding: 12px;
  font-weight: bold;
  border-radius: 24px; /* Bordure arrondie pour le bouton */
}

/* Séparateur */
.custom-modal-separator {
  height: 1px;
  background-color: #ddd;
  margin: 16px 0;
}

/* Bouton "Pick up" avec bordure arrondie */
.custom-modal-pickup-button {
  text-align: center;
  border-radius: 24px; /* Bordure arrondie pour le bouton */
}

/* Adresse en gras */
.custom-modal-pickup-address {
  font-weight: bold;
}

/* Texte "Sign in" */
.custom-modal-signin-text {
  font-size: 14px;
  text-align: center;
  color: #666;
}

/* Bouton "Sign in" avec bordure arrondie */
.custom-modal-signin-button {
  padding: 12px;
  font-weight: bold;
  border-radius: 24px; /* Bordure arrondie pour le bouton */
}

/* Section de contact */
.custom-modal-contact {
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
  color: #666;
}
