.all-products-page {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 30px;
  }
  
  /* Conteneur pour l'image et le texte */
  .all-products-image-text-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .all-products-image-column {
    flex: 1;
    max-width: 75%; /* La colonne d'image occupe 75% de la largeur */
    height: 350px; /* Ajustez selon la hauteur souhaitée */
    position: relative;
    overflow: hidden; /* Empêche l'image de dépasser */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10%; /* Permet à l'image de dépasser les marges de l'écran */
  }
  
  .all-products-image {
    width: auto;
    height: 120%; /* L'image dépasse légèrement la hauteur définie */
    object-fit: cover; /* S'assure que l'image remplit bien le conteneur */
    transform: rotate(90deg); /* Pivote l'image à 90 degrés */
  }
  
  .all-products-text-column {
    flex: 1;
    max-width: 25%; /* Le texte occupe 25% de la largeur */
    padding: 1rem;
}
  
  .all-products-text-column h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .all-products-text-column p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  /* Liste des produits */
  .all-products-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }
  
  .all-products-item {
    background-color: var(--light-color);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .all-products-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
  
  .all-products-item-image img,
  .all-products-item-image video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .all-products-item-info {
    margin-top: 15px;
  }
  
  .all-products-brand {
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .all-products-name {
    font-size: 1.1rem;
    margin: 10px 0;
  }
  
  .all-products-price {
    color: var(--dark-color);
    font-size: 1.1rem;
  }
  