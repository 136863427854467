/* Product.css */

/* Liste des produits */
.product-list {
  display: grid; /* Activer CSS Grid */
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr)); /* Colonnes réactives avec minimum 200px par colonne */
  gap: 12px; /* Espacement entre les éléments */
}

/* Chaque élément du produit */
.product-item {
  background: #fff; /* Fond blanc pour chaque produit */
 
  text-align: left; /* Centrer le texte */
}

.border-right {
  margin-left: 10px;
  border-right: 1px solid #ddd; /* Bordure légère à droite */
}

/* Image des produits */
.product-image {
  width: auto; /* Garder la largeur d'origine */
  height: auto; /* Garder la hauteur d'origine */
  max-width: 100%; /* Limite la largeur à 100% du conteneur */
  max-height: 150px; /* Limite la hauteur à 200px pour les produits */
  margin-bottom: 10px;
}

/* Prix original */
.original-price {
  text-decoration: line-through; /* Prix original barré */
  color: #888; /* Couleur gris pour le prix original */
}

/* Prix actuel */
strong {
  color: #d9534f; /* Couleur rouge pour le prix actuel */
}

/* Ligne verticale entre les éléments */
.vertical-line {
  width: 1px; /* Épaisseur de la ligne */
  background-color: #ddd; /* Couleur de la ligne */
  height: 20px; /* Hauteur de la ligne, ajustez selon vos besoins */
  margin: 0 8px; /* Espacement horizontal autour de la ligne */
}

/* Conteneur des cartes produits */
.product-card-container {
  display: flex;
  justify-content: space-between; /* Espace entre les éléments */
  align-items: center; /* Centre verticalement les éléments */
}

/* Conteneur droit */
.right-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments à droite */
  gap: 4px; 
}

/* Responsive styles: Toujours 2 colonnes minimum */
@media (max-width: 448px) {
  .product-list {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes au minimum */     
  }
  .product-item:nth-child(2n) {
    border-right: none !important; /* Supprimer la bordure du dernier élément sur chaque ligne */
  }
}


